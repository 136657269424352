@font-face {
  font-family: 'SF Pro';
  src: url('https://sf.abarba.me/SF-Pro-Display-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro';
  src: url('https://sf.abarba.me/SF-Pro-Display-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html, body {
  margin: 0;
  padding: 0;
  background-color: #000;
}

.minimal-home {
  background-color: #000;
  color: #fff;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  font-family: 'SF Pro', -apple-system, BlinkMacSystemFont, sans-serif;
  position: relative;
  overflow: hidden;
}

.top-right {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 10;
}

.email-link {
  color: #fff;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.9;
}

.center-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-emoji {
  font-size: 80px;
  line-height: 1;
  margin-bottom: 10px;
}

.logo-text {
  font-size: 54px;
  font-weight: 600;
  margin: 0;
}

.description {
  font-size: 20px;
  font-weight: 500;
  max-width: 500px;
  margin: 0;
  opacity: 0.8;
  font-family: 'SF Pro', -apple-system;
}

.contact-button {
  margin-top: 10px;
  background: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 20px;
  border-radius: 6px;
  font-family: 'SF Pro', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.contact-button:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.5);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .email-link {
    font-size: 14px;
  }
  
  .logo-emoji {
    font-size: 60px;
  }
  
  .logo-text {
    font-size: 28px;
  }
  
  .description {
    font-size: 16px;
    max-width: 90%;
    padding: 0 20px;
  }

  .top-right {
    top: 20px;
    right: 20px;
  }
}

/* Small mobile devices */
@media (max-width: 375px) {
  .logo-emoji {
    font-size: 50px;
  }
  
  .logo-text {
    font-size: 24px;
  }
  
  .description {
    font-size: 14px;
  }
  
  .email-link {
    font-size: 12px;
  }
  
  .contact-button {
    font-size: 14px;
    padding: 8px 16px;
  }
} 